import React, { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import BackArrow from "./back_arrow.png"
import * as Mui from "@mui/material"
import { getPort, getChartData, getLiveIndex, setToPending, investPort, approvePort, rejectPort, createEmail } from "../user.js"
import { editPort, dailyUpdatePortfolio, getSocialInfo, like, favorite, comment, getComments } from "../user.js"
import { Line } from "react-chartjs-2"
import SecureStorage from "react-secure-storage"
import { colorCode, RiskBar } from "../Cards/cardv2"
import { Delete } from "../user.js"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import StarIcon from "@mui/icons-material/Star"
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt"
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt"
import SectorPercentages from "./components/SectorPercentages.tsx"

const adminUserIds = [16, 15, 51]

function LineGraph({ data, index, labels, unit }) {
  if (data === undefined || labels === undefined) {
    return <div>Loading...</div>
  }
  // for(let label in labels){
  //   labels[label] = new Date(labels[label]*1000);
  // }
  // 

  const datas = {
    labels: labels,
    datasets: [
      {
        label: "Back Test Data",
        data: data,
        fill: false,
        // backgroundColor: '#949494',
        // borderColor: '#1B263B', // Default border color
        segment: {
          borderColor: (context) => {
            return context.p0DataIndex < index ? "#949494" : "#1B263B"
          },
        },
        pointBackgroundColor: (context) => {
          return context.dataIndex === data.length - 1 ? "#1B263B" : "#949494"
        },
        pointRadius: (context) => {
          return context.dataIndex === data.length - 1 ? 5 : 0 // Make the last point radius bigger
        },
        pointBorderWidth: 0,
        pointBorderColor: "transparent",

        cubicInterpolationMode: "monotone",
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: "#1B263B",
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          title: (context) => {
            const date = new Date(context[0].parsed.x * 1000)
            if (unit === "day") {
              return date.toLocaleDateString()
            } else {
              return date.toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: true,
              })
            }
          },
          label: (context) => {
            const label = context.dataIndex < index ? "BackTest Data" : "Live Data"
            const value = context.raw
            return `${label}: ${value.toFixed(2)}`
          },
          labelColor: (context) => ({
            borderColor: context.dataIndex < index ? "#949494" : "#1B263B",
            backgroundColor: context.dataIndex < index ? "#949494" : "#1B263B",
          }),
        },
      },
    },
    // interaction: {
    //   mode: 'x',
    //   intersect: false,
    //   axis: 'xy',
    // },
    scales: {
      x: {
        type: "time",
        // time: {
        //   unit: 'minute', // Adjust the unit as needed (e.g., 'minute', 'hour', 'day')
        // },
        ticks: {
          display: true,
          callback: (value, index, values) => {
            const date = new Date(value * 1000) // Convert Unix timestamp to JavaScript Date object
            if (unit === "minute") {
              return date.toLocaleString("en-US", {
                // year: 'numeric',
                // month: '2-digit',
                // day: '2-digit',
                hour: "2-digit",
                minute: "2-digit",
                // second: '2-digit',
                hour12: true,
              })
            } else if (unit === "hour") {
              return date.toLocaleString("en-US", {
                // year: 'numeric',
                // month: '2-digit',
                day: "2-digit",
                hour: "2-digit",
                // minute: '2-digit',
                // second: '2-digit',
                hour12: true,
              })
            } else if (unit === "day") {
              return date.toLocaleDateString()
            }
          },

          // callback: function(value, index, values) {
          //   const date = new Date(value * 10000); // Convert Unix timestamp to JavaScript Date object
          //   return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Format time as HH:MM
          // },
          color: "#1B263B",
        },
        title: {
          display: false,
          text: "Date",
          color: "#1B263B",
        },
      },
      y: {
        ticks: {
          display: true,
          text: "Value",
          color: "#1B263B",
        },
        grid: {
          color: "#949494",
          borderColor: "red",
        },
      },
    },
  }
  return (
    <div style={{ textAlign: "center", width: "900px", minHeight: "350px" }}>
      <Line data={datas} options={options} className="canvas" />
    </div>
  )
}

function DescriptionField({ description }) {
  return (
    <Mui.Box sx={{
      width: "40%",
    }}>
    <Mui.Typography>
      Description
    </Mui.Typography>
    <Mui.TextField
      value={description}
      readOnly
      multiline
      variant="outlined"
      sx={{
        boxSizing: "border-box",
        display: "flex",
        flexGrow: 1,
        marginTop: "10px",
        backgroundColor: "#F0F0F5",
        border: "2px solid #1B263B",
        borderRadius: "5px",
        color: "#1B263B", // Text color
        cursor: "default", // Change cursor to default
        resize: "none", // Disable resizing
        outline: "none", // Remove focus outline
        padding: "3px", // Add padding for better readability
        fontFamily: "inherit", // Inherit font from parent
        fontSize: "inherit", // Inherit font size from parent
        
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            height: "100%", // Ensure the fieldset takes full height
            borderColor: "transparent", // Remove the default border
          },
          "&:hover fieldset": {
            borderColor: "transparent", // Remove the hover border
          },
          "&.Mui-focused fieldset": {
            borderColor: "transparent", // Remove the focused border
          },
        },
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "#1B263B", // Ensure text color is applied when disabled
        },
      }}
      InputProps={{
        readOnly: true,
      }}
    />
    </Mui.Box>
  )
}
function DisplaySocial({ id }) {
  const [data, setData] = React.useState({})
  const [rerender, setRerender] = React.useState(0)
  useEffect(() => {
    const fetchSocial = async () => {
      console.log("first fetching social {} {} ", SecureStorage.getItem("userId"), id)
      const data = await getSocialInfo(SecureStorage.getItem("userId"), id)
      setData(data)
    }
    fetchSocial()
  }, [id, rerender])

  const handleLikeClicked = async () => {
    await like(SecureStorage.getItem("userId"), id)
    setRerender(rerender + 1)
  }
  const handleFavoriteClicked = async () => {
    await favorite(SecureStorage.getItem("userId"), id)
    setRerender(rerender + 1)
  }
    return (
    Object.keys(data).length > 0 && (
      <div style={{ display: "flex", gap: "10px" }}>
        <Mui.IconButton
          onClick={handleFavoriteClicked}
          sx={{
            color: "#080823",
            fontSize: "35px",
          }}
        >
          {data.favorite ? <StarIcon fontSize="inherit" /> : <StarBorderIcon fontSize="inherit" />}
        </Mui.IconButton>

        <Mui.IconButton
          onClick={handleLikeClicked}
          sx={{
            color: "#080823",
            fontSize: "35px",
          }}
        >
          {data.like ? <ThumbUpAltIcon fontSize="inherit" /> : <ThumbUpOffAltIcon fontSize="inherit" />}
        </Mui.IconButton>
      </div>
    )
  )
}


const PortfolioModal = ({ isOpen, handleCreationClose }) => {
  if (!isOpen) return null;

  return (
      <div
          style={{
              position: 'fixed',
              top: 0,
              left: 0,
              background: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backdropFilter: 'blur(5px)',
              width: '100vw',
              height: '100vh',
          }}
      >
          <div
              style={{
                  background: 'white',
                  padding: '20px',
                  borderRadius: '5px',
                  textAlign: 'center',
              }}
          >
              <div style={{ fontSize: '20px', padding: '20px' }}>This port is currently private. To make it public, click the publish button located at the top of the page</div>
              <div
                  onClick={handleCreationClose}
                  style={{
                      textAlign: 'center',
                      border: '2px solid #1B263B',
                      display: 'inline-block',
                      padding: '5px 15px',
                      marginBottom: '10px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      backgroundColor: 'white',
                      color: '#1B263B',
                  }}
                  onMouseEnter={(e) => {
                      e.target.style.backgroundColor = '#1B263B';
                      e.target.style.color = '#F0F0F5';
                  }}
                  onMouseLeave={(e) => {
                      e.target.style.backgroundColor = 'white';
                      e.target.style.color = '#1B263B';
                  }}
              >
                  Back to Page
              </div>
          </div>
      </div>
  );
};

function Comments({ id }) {
  const [comments, setComments] = React.useState([])

  const commentExample = {
    user: "John Doe",
    date: "2021-10-10",
    text: "This is a comment",
  }
  React.useEffect(() => {
    const fetchComments = async () => {
      if (id) {
        console.log("fetching comments: ", id)
        const comments = await getComments(id)
        console.log("fetching comments: ", comments)
        setComments(comments)
      }

    }
    fetchComments()
  }, [id])

  const [commentDraft, setCommentDraft] = React.useState("")
  const handleCommentDraftChange = (event) => {
    setCommentDraft(event.target.value)
  }

  return (
    <Mui.Box sx={{ padding: "20px", backgroundColor: "#f9f9f9", borderRadius: "10px" }}>
      <Mui.Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "40px",
          gap: "10px",
        }}
      >
        <Mui.TextField
          id="outlined-basic"
          placeholder="Write your comment here..."
          value={commentDraft}
          onChange={handleCommentDraftChange}
          sx={{
            flex: 1,
            ".MuiOutlinedInput-root": {
              height: "100px",
              borderRadius: "10px",
              alignItems: "flex-start", // Align text to the top-left
              "& fieldset": {
                border: "1px solid #ccc",
              },
              ":hover fieldset": {
                borderColor: "#080823",
              },
            },
            ".MuiInputBase-input": {
              padding: "6px",
              fontSize: "16px",
              lineHeight: "1.5",
            },
          }}
          multiline // Allow multi-line comments
        />

        <Mui.Button
          onClick={() => comment(SecureStorage.getItem("userId"), id, commentDraft)}
          sx={{
            height: "50px",
            minWidth: "100px",
            borderRadius: "25px", // Rounded button
            backgroundColor: "#080823",
            color: "white",
            fontSize: "24px",
            fontWeight: "bold",
            textTransform: "none",
            ":hover": {
              backgroundColor: "#333",
            },
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          Post
        </Mui.Button>
      </Mui.Box>
      {comments.map((comment) => {
        const formattedDate = new Date(comment.timestamp).toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        })

        return (
          <Mui.Box
            key={comment.id}
            sx={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid #ccc",
              borderRadius: "10px",
              padding: "10px",
              marginBottom: "10px",
              backgroundColor: "#fff",
            }}
          >
            <Mui.Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <Mui.Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>{comment.username}</Mui.Typography>
              <Mui.Typography sx={{ fontSize: "12px", color: "#888" }}>{formattedDate}</Mui.Typography>
            </Mui.Box>
            <Mui.Typography sx={{ fontSize: "14px", lineHeight: "1.5" }}>{comment.comment}</Mui.Typography>
          </Mui.Box>
        )
      })}
    </Mui.Box>
  )
}

function PortInfo() {
  const userId = SecureStorage.getItem("userId")
  const navigate = useNavigate()
  const { id } = useParams()
  // id represents the id of the portfolio
  // type represents the type of the portfolio Public, Private, Pending
  const [reserve, setReserve] = React.useState(0)
   function handleProfile(username,id) {
      // console.log(id)
      SecureStorage.setItem('u_id',username)
      console.log(SecureStorage.getItem('u_id'))
      navigate(`/profile/${username}/${id}`);
    }
  const timeframes = ["1D", "1W", "1M", "1Y", "ALL"]
  const timeframesTOinterval = {
    "1D": "minute",
    "1W": "hour",
    "1M": "day",
    "1Y": "day",
    ALL: "day",
  }
  const [index, setIndex] = React.useState(0)
  const [selectedTimeframe, setSelectedTimeframe] = React.useState("1D")
  console.log("selectedTimeframe: ", selectedTimeframe)
  const [data, setData] = React.useState({})
  const [graphData, setGraphData] = React.useState({})
  const [isAdmin, setIsAdmin] = React.useState(adminUserIds.includes(userId))
  const [isOwner, setIsOwner] = React.useState(false)
  const [showStockModal, setShowStockModal] = React.useState(false)
  const [showStockButton, setShowStockButton] = React.useState(true)
  const [showInvesting, setShowInvesting] = React.useState(false)
  const [showApproval, setShowApproval] = React.useState(false)
  const [isInvesting, setIsInvesting] = React.useState(false)
  const [showEditButton, setShowEditButton] = React.useState(false)
  const [rerender, setRerender] = React.useState(0)
  const [ratio, setRatio] = React.useState(1)
  const [showDeleteButton, setShowDeleteButton] = React.useState(false)
  const [author, setAuthor] = React.useState({})
  const [modalOpen, setModalOpen] = React.useState(true);
  const [sectorData, setSectorData] = React.useState([])


    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };
  // const sectorData = [
  //     { name: 'Technology', percentage: 25 },
  //     { name: 'Healthcare', percentage: 20 },
  //     { name: 'Finance', percentage: 15 },
  //     { name: 'Consumer Goods', percentage: 10 },
  //     { name: 'Energy', percentage: 10 },
  //     { name: 'Utilities', percentage: 5 },
  //     { name: 'Real Estate', percentage: 5 },
  //     { name: 'Materials', percentage: 5 },
  //     { name: 'Industrials', percentage: 5 },
  //   ];

    function getSectorData(portStocks) {
      let sectorData = {}
      for (const stock of portStocks) {
        console.log("stock: ", stock)
        if (stock.stockId.sector in sectorData) {
          sectorData[stock.stockId.sector] += stock.curPerc
        } else {
          sectorData[stock.stockId.sector] = stock.curPerc
        }
      }
      sectorData = Object.entries(sectorData).map(([key, value]) => ({ name: key, percentage: value }))
      return sectorData;
    }

  const handleDelete = async () => {
    await Delete(id)
    navigate("/home")
  }
  const decodeHtmlEntities = (text) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(text, "text/html")
    return doc.documentElement.textContent
  }
  React.useEffect(() => {
    const fetchData = async () => {
      const portData = await getPort(id)
      decodeHtmlEntities(portData)
      setAuthor(portData.author)
      console.log("portStocks: ", portData.portStocks)
      const sectorData = getSectorData(portData.portStocks)
      console.log("sectorData: ", sectorData)
      setSectorData(sectorData)
      setData(portData)
      // console.log("ratio2: ",portData.ratio);
      setReserve(portData.reserve)
      setIsOwner(userId === portData.id)
      if (portData.ratio !== 0) {
        console.log("ratio1: ", portData.ratio)
        setRatio(portData.ratio)
        setIsInvesting(true)
      }
    }
      
      
      fetchData();
      const intervalId = setInterval(fetchData, 60000);
    

    // return () => clearInterval(intervalId);
  }, [selectedTimeframe, id, rerender])

  React.useEffect(() => {
    const fetchData = async () => {
      const graphData = await getChartData(id, ratio, selectedTimeframe, timeframesTOinterval[selectedTimeframe])
      setGraphData(graphData)
      console.log("just data: ", data)
      const index = await getLiveIndex(graphData.dateHist, data.creationDate)
      setIndex(index)
    }
    console.log("fetching data")
    if (Object.keys(data).length > 0) {
      fetchData()
      const intervalId = setInterval(fetchData, 60000)
      return () => clearInterval(intervalId)
    }

    

    
  }, [selectedTimeframe, data])

  React.useEffect(() => {
    if (isOwner && isAdmin) {
      setShowDeleteButton(true)
    }
    if (data.status) {
      console.log("isOwner: ", isOwner)
      console.log("isAdmin: ", isAdmin)
      console.log("status: ", data.status)
      if (data.status === "private" && !isOwner) {
        navigate("/home")

      } 
      if(!isOwner){
        setShowInvesting(true);
      }
      
      if(data.status === "pending" ){
        
        if(!isOwner){
          if(!isAdmin){
            navigate("/home")
          }
        }
        
          if(isAdmin){
            setShowApproval(true);
          }
        
    }
    if(isOwner || isAdmin){
      setShowStockButton(true);
    }
    // if (data.status === "private" && isOwner){
    //   setShowEditButton(true);
    // }
  }
  }, [data, isOwner, isAdmin])

 






  return(
   <Mui.Box sx={{
    display: "flex",
    flexDirection: "column",   
    width: "100%",
    backgroundColor: "#F6F5F5",
    padding: "30px",
    overflow: "auto",
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    '-ms-overflow-style': 'none',  // IE and Edge
    'scrollbar-width': 'none',     // Firefox
    marginBottom: "50px",
    
   }}>

      {data.status === "private" && modalOpen && (
                <PortfolioModal isOpen={modalOpen} handleCreationClose={handleCloseModal} />
            )}
      {showStockModal && <StockModal stockData={data.portStocks} setShowStockModal={setShowStockModal} />}
      <Mui.Box
        sx={{
          display: "flex",
          gap: "10px",
          zIndex: "1",
        }}
      >
        <Mui.Button
          sx={{
            width: "149px",
            height: "39px",
            borderRadius: "10px",
            border: "1px solid #080823",
            backgroundColor: "#FFFFFF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => navigate("/home")}
        >
          <img src={BackArrow || "/placeholder.svg"} style={{ marginRight: "8px" }} />{" "}
          <Mui.Typography
            sx={{
              color: "#080823",
              fontWeight: "700",
              fontSize: "25px",
              textAlign: "center",
            }}
          >
            Back
          </Mui.Typography>
        </Mui.Button>
        {data.status === "private" && (
          <Mui.Button
            sx={{
              width: "149px",
              height: "39px",
              borderRadius: "10px",
              border: "1px solid #080823",
              backgroundColor: "#FFFFFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => setToPending(data.id)}
          >
            <Mui.Typography
              sx={{
                color: "#080823",
                fontWeight: "700",
                fontSize: "25px",
                textAlign: "center",
              }}
            >
              Publish
            </Mui.Typography>
          </Mui.Button>
        )}
        {showStockButton && (
          <Mui.Button
            sx={{
              width: "149px",
              height: "39px",
              borderRadius: "10px",
              border: "1px solid #080823",
              backgroundColor: "#FFFFFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => setShowStockModal(true)}
          >
            <Mui.Typography
              sx={{
                color: "#080823",
                fontWeight: "700",
                fontSize: "25px",
                textAlign: "center",
              }}
            >
              Stocks
            </Mui.Typography>
          </Mui.Button>
        )}

        {showEditButton && (
          <Mui.Button
            sx={{
              width: "149px",
              height: "39px",
              borderRadius: "10px",
              border: "1px solid #080823",
              backgroundColor: "#FFFFFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}

            onClick={() => navigate(`/edit/${data.id}`)}
          >
            <Mui.Typography
              sx={{
                color: "#080823",
                fontWeight: "700",
                fontSize: "25px",
                textAlign: "center",
              }}
            >
              Edit
            </Mui.Typography>
          </Mui.Button>
        )}

        {showDeleteButton && (
          <Mui.Button
            sx={{
              width: "149px",
              height: "39px",
              borderRadius: "10px",
              border: "1px solid #080823",
              backgroundColor: "#FFFFFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleDelete}
          >
            <Mui.Typography
              sx={{
                color: "#080823",
                fontWeight: "700",
                fontSize: "25px",
                textAlign: "center",
              }}
            >
              Delete
            </Mui.Typography>
          </Mui.Button>
        )}
        {data.id && <DisplaySocial id={data.id} />}
      </Mui.Box>
      <Mui.Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >

        <Mui.Typography
          sx={{
            color: "#080823",
            fontWeight: "700",
            fontSize: "35px",
            lineHeight: "42.7px",
          }}
        >
          {data.title}
        </Mui.Typography>
        <Mui.Typography
          sx={{
            color: "#61616E",
            fontSize: "18px",
            lineHeight: "22px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick = {() => handleProfile(data.author.username,data.author.id)}
        >
          By {author.username}
          <Mui.Box
            sx={{
              display: "flex",
              gap: "5px",
            }}
          >
            {Object.keys(author).length > 0
              ? author.tags.map((role) => (
                  <Mui.Typography
                    sx={{
                      width: "34px",
                      height: "13px",
                      backgroundColor: "#080823",
                      borderRadius: "5px",
                      padding: ".5px 9px .5px 9px",
                      color: "white",
                      fontSize: "8px",
                      textAlign: "center",
                      alignSelf: "center",
                      marginLeft: "5px",
                    }}
                  >
                    {role.name}
                  </Mui.Typography>
                ))
              : ""}
          </Mui.Box>

        </Mui.Typography>
        <div
          style={{
            display: "flex",
            width: "900px",
            justifyContent: "space-between", // Adjusts spacing between items
            alignItems: "center", // Aligns items vertically in the center
          }}
        >
          {timeframes.map((timeframe) => (
            <div
              key={timeframe}
              style={{
                color: selectedTimeframe === timeframe ? "#080823" : "#61616E", // Changes color based on selection
                cursor: "pointer", // Changes cursor to pointer on hover
                transition: "color 0.3s ease", // Smooth transition for hover effect
              }}
              // onMouseEnter={(e) => {
              //   e.currentTarget.style.color = "#1e90ff"; // Hover color
              // }}
              // onMouseLeave={(e) => {
              //   e.currentTarget.style.color = "#080823"; // Reset color
              // }}
              onClick={(e) => setSelectedTimeframe(timeframe)}
            >
              {timeframe}
            </div>
          ))}
        </div>

        <LineGraph
          data={graphData.valueHist}
          labels={graphData.dateHist}
          index={index}
          unit={timeframesTOinterval[selectedTimeframe]}
        />
        <Mui.Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "90%",
          }}
        >
          <Mui.Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "15px",
              paddingTop: "20px",
              maxHeight: "100%",
              transform: "scale(.9)",
            }}
          >
            <Detail name={"Value"}>${data.lastValue}</Detail>
            <Detail name={"Annual Return"}>{colorCode(data.annualReturn, "percent")}</Detail>
            <Detail name={"Daily Change"}>{colorCode(data.dailyPnl, "dollar")}</Detail>
            <Detail name={"Risk Index"}>
              <Mui.Box
                sx={{
                  transform: "scale(1.5)", // Scale up the RiskBar component
                  transformOrigin: "left", // Adjust the origin as needed
                }}
              >
                <RiskBar props={data} />
              </Mui.Box>
            </Detail>
            <Detail name={"Expense Ratio"}>{data.expenseRatio}</Detail>
          </Mui.Box>
          <Mui.Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "15px",
              paddingTop: "20px",
              maxHeight: "100%",
              transform: "scale(.9)",
            }}
          >
            <Detail name={"Sharpe Ratio"}>{data.sharpeRatio}</Detail>
            <Detail name={"Max Drawdown"}>{colorCode(data.maxDrawdown, "percent")}</Detail>
            <Detail name={"PnL"}>{colorCode(data.totalPnL, "percent")}</Detail>
          </Mui.Box>
          {showInvesting && (
            <InvestmentTabs
              reserve={reserve}
              portId={data.id}
              isInvesting={isInvesting}
              setRerender={setRerender}
              rerender={rerender}
            />
          )}
          {showApproval && <Approval portId={data.id} author={data.author} name = {data.title}/>}
        </Mui.Box>
      </Mui.Box>
      <Mui.Box sx={{display: "flex", justifyContent:"spaced-between", width: "100%", paddingTop: "20px",gap: "20px"}}>
      <DescriptionField description={data.description} />
      <SectorPercentages sectors={sectorData} />
      </Mui.Box>
      <div
        style={{
          height: "20px",
        }}
      ></div>
      <Comments id={data.id} />
    </Mui.Box>
  )
}

function InvestmentTabs({ reserve, portId, isInvesting, setRerender, rerender }) {
  const [buyInput, setBuyInput] = React.useState("")
  const [sellInput, setSellInput] = React.useState("")
  const [showSell, setShowSell] = React.useState(false)
  if ((reserve !== 0 || isInvesting) && !showSell) {
    setShowSell(true)
  }
  const handleBuyChange = (event) => {
    if (/^\d*\.?\d*$/.test(event.target.value)) {
      setBuyInput(event.target.value)
    }
  }
  const handleSellChange = (event) => {
    if (/^\d*\.?\d*$/.test(event.target.value)) {
      setSellInput(event.target.value)
    }
  }

  async function SellButtonPressed() {
    await investPort(portId, SecureStorage.getItem("userId"), -Number.parseFloat(sellInput))
    setRerender(rerender + 1)
  }
  async function BuyButtonPressed() {
    await investPort(portId, SecureStorage.getItem("userId"), Number.parseFloat(buyInput))
    setRerender(rerender + 1)
  }

  return (
    <>
      <Mui.Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {reserve !== 0 && (
          <Mui.Typography
            sx={{
              color: "#4F1313",
              fontSize: "15px",
            }}
          >
            {reserve > 0 ? "Buying" : "Selling"} ${Math.abs(reserve)} @ OPEN (9:30 EST)
          </Mui.Typography> )}

        <Mui.Typography
          sx={{
            color: "#080823",
            fontSize: "20px",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "10px",
          }}
        >
          Buy
        </Mui.Typography>
        <Mui.TextField
          id="outlined-basic"
          placeholder="$5 minimum"
          value={buyInput}
          onChange={handleBuyChange}
          sx={{
            width: "397px", // Set the width of the Mui.TextField
            paddingBottom: "20px",
            ".MuiOutlinedInput-root": {
              height: "42px", // Set the height of the input field
              borderRadius: "10px", // Rounded corners
              "& fieldset": {
                border: "0.5px solid #08082380", // Border styling
              },
            },
            ".MuiInputBase-input": {
              padding: "8px", // Adjust padding inside the input
              fontSize: "20px", // Adjust font size if necessary
            },
          }}
        />
        <Mui.Button
          sx={{
            border: "2px solid #080823",
            borderRadius: "10px",
            height: "42px",
            width: "200px",
            color: "#080823",
            fontSize: "17px",
            textTransform: "none",
          }}
          onClick={BuyButtonPressed}
        >
          Confirm Buy Order
        </Mui.Button>
        {showSell && (
          <>
            <Mui.Typography
              sx={{
                color: "#080823",
                fontSize: "20px",
                fontWeight: "700",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "10px",
              }}
            >
              Sell
            </Mui.Typography>
            <Mui.TextField
              id="outlined-basic"
              placeholder="$5 minimum"
              value={sellInput}
              onChange={handleSellChange}
              sx={{
                width: "397px", // Set the width of the Mui.TextField
                paddingBottom: "20px",
                ".MuiOutlinedInput-root": {
                  height: "42px", // Set the height of the input field
                  borderRadius: "10px", // Rounded corners
                  "& fieldset": {
                    border: "0.5px solid #08082380", // Border styling
                  },
                },
                ".MuiInputBase-input": {
                  padding: "8px", // Adjust padding inside the input
                  fontSize: "20px", // Adjust font size if necessary
                },
              }}
            />
            <Mui.Button
              onClick={SellButtonPressed}
              sx={{
                border: "2px solid #080823",
                borderRadius: "10px",
                height: "42px",
                width: "200px",
                color: "#080823",
                fontSize: "17px",
                textTransform: "none",
              }}
            >
              Confirm Sell Order
            </Mui.Button>
          </>
        )}
      </Mui.Box>
    </>
  );
}

function StockModal({ stockData, setShowStockModal }) {
  return (
    <Mui.Box
      sx={{
        position: "absolute",
        background: "transparent",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // backdropFilter: 'blur(5px)',

        width: "100%",
        height: "100%",
        zIndex: "100",
      }}
      onClick={() => setShowStockModal(false)}
    >
      <Mui.Box
        sx={{
          background: "white",
          padding: "20px",
          borderRadius: "5px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
          height: "400px",
          overflow: "auto",
          zIndex: "101",
        }}
      >
        {stockData.map((stock) => (
          <Mui.Typography
            sx={{
              fontSize: "20px",
              color: stock.status === "active" ? "#080823" : "red",
              marginBottom: "10px",
            }}
          >
            {stock.stockId.ticker} - {stock.curPerc.toFixed(2)}%
          </Mui.Typography>
        ))}
      </Mui.Box>
    </Mui.Box>
  )
}
function Approval({ portId, author, name }) {
  const [reason, setReason] = React.useState("")
  
  
      const handleApproveClick = async () => {
      // if(status === "pending"){

      
      await approvePort(portId);
      createEmail(author.email,`Thank you for submitting your port “${name}” for review! Your port has been approved and is now public on the website.`,`${author.username} Port Approval - ${name}`);
    //   } else{
    //     await editPort(portId);
    //     await dailyUpdatePortfolio(portId);
    //   }
    }
    const handleRejectClick = async () => {
      await rejectPort(portId);
      createEmail(author.email,`Thank you for submitting your port “${name}” for review! We regret to inform you that this port has been rejected for the following reason: “${reason}”. Feel free to edit it and resubmit for review.`,`${author.username} Port Rejection - ${name}`);
    }

//   const handleApproveClick = async () => {
//     if (status === "pending") {
//       const response = await approvePort(portId)
//     } else {
//       await editPort(portId)
//       await dailyUpdatePortfolio(portId)
//     }
//   }
//   const handleRejectClick = async () => {
//     const response = await rejectPort(portId, reason)
//   }

  return (
    <Mui.Box
      sx={{
        gap: "20px",
        alignItems: "center",
      }}
    >
      <Mui.Button
        sx={{
          border: "2px solid #080823",
          borderRadius: "10px",
          height: "42px",
          width: "200px",
          color: "#080823",
          fontSize: "17px",
          textTransform: "none",
        }}
        onClick={handleApproveClick}
      >
        Approve
      </Mui.Button>
      <Mui.Button
        sx={{
          border: "2px solid #080823",
          borderRadius: "10px",
          height: "42px",
          width: "200px",
          color: "#080823",
          fontSize: "17px",
          textTransform: "none",
        }}
        onClick={handleRejectClick}
      >
        Reject
      </Mui.Button>
      <Mui.TextField
        id="outlined-basic"
        placeholder="Reason for rejection"
        value={reason} // Bind searchQuery state to the Mui.TextField
        onChange={(event) => setReason(event.target.value)} // Update state on input change
        sx={{
          width: "100%", // Set the width of the Mui.TextField
          ".MuiOutlinedInput-root": {
            height: "70px", // Set the height of the input field
            borderRadius: "5px", // Rounded corners
            "& fieldset": {
              border: "0.5px solid #08082380", // Border styling
            },
          },
          ".MuiInputBase-input": {
            padding: "8px", // Adjust padding inside the input
            paddingLeft: "30px",
            fontSize: "20px", // Adjust font size if necessary
          },
        }}
      />
    </Mui.Box>
  )
}

function Detail({ name, children }) {
  return (
    <Mui.Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "384px",
      }}
    >
      <Mui.Typography
        sx={{
          color: "#080823",
          fontSize: "25px",
          fontWeight: "700",
        }}
      >
        {name}
      </Mui.Typography>

      <Mui.Typography
        sx={{
          fontSize: "25px",
          fontWeight: "700",
          color: "#080823",
        }}
      >
        {children}
      </Mui.Typography>
    </Mui.Box>
  )
}

export default PortInfo

