import React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";  
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import * as Mui from "@mui/material";
import Grid from "@mui/material/Grid2";
import {DummyPersonalCard, PersonalCard} from "../Cards/cardv2";
import {searchPersonalPorts, searchInvestPorts, getFavoritesPorts} from "../user.js";
import SecureStorage from 'react-secure-storage';
import News from './news.jsx'
import Box from '@mui/material/Box';
function MyPorts({setPortData}) {
    const [portDatas, setPortDatas] = React.useState([]);
    
  
    return (
      <>
    
    <Grid
              container
              sx={{
                backgroundColor: "#F6F5F5",
                height: "auto",
                borderLeft: "1px solid #08082380",
                // padding: "10px 20px 10px 20px",
                paddingTop: "10px",
                display: "flex",
                alignContent: "flex-start",
                justifyContent: "center",
                width: "420px",
                flexShrink: 0,
              }}
            >
              <MyPortsSearchBar setPortDatas= {setPortDatas}/>
              <Grid
                container
                spacing={4}
                sx={{
                  paddingTop: "20px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  display: "flex",
                  flexDirection: "column", // Items flow from top to bottom
                  flexWrap: "nowrap", // Prevent wrapping to the next row
                  justifyContent: "flex-start", // Align items to the top
                  alignContent: "center", // Center items horizontally
                  height: "90%", 
                  overflowY: "auto", 
                  overflowX: "hidden",
                  scrollbarWidth: "none", 
              "&::-webkit-scrollbar": { 
                display: "none",
              },
                }}
              >
                {portDatas.map((investment) => {
  
  
                  // TODO set data here--------------------------------------------------------------------------------------
                  return <PersonalCard pportData={investment}/>;
                })}

                  {/* scrolling doesnt go all the way if these arent here */}
                   <DummyPersonalCard/>
                  {/*<DummyPersonalCard/>
                  <DummyPersonalCard/>
                  <DummyPersonalCard/> */}
                  {/* <div style ={{height: "0px"}}></div> */}
          
              </Grid>
            
              {/* <News
                sx={{
                  paddingTop: "20px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  paddingBottom: "50px",
                  display: "flex",
                  flexDirection: "column", // Items flow from top to bottom
                  flexWrap: "nowrap", // Prevent wrapping to the next row
                  justifyContent: "flex-start", // Align items to the top
                  alignContent: "center", // Center items horizontally
                  height: "100%", 
                  // overflowY: "auto", 
                  // overflowX: "hidden",
              //     scrollbarWidth: "none", 
              // "&::-webkit-scrollbar": { 
              //   display: "none",
              // },
                }} /> */}
            </Grid>
            
        
         
           </>

//     <Box
//   sx={{
//     height: "100vh", // Full viewport height
//     overflowY: "auto", // Enable vertical scrolling
//     overflowX: "hidden", // Prevent horizontal scrolling
//     scrollbarWidth: "thin", // For Firefox
//     "&::-webkit-scrollbar": {
//       width: "8px", // Scrollbar width for WebKit browsers
//     },
//     "&::-webkit-scrollbar-thumb": {
//       backgroundColor: "#cccccc", // Scrollbar thumb color
//       borderRadius: "4px", // Rounded corners for the scrollbar thumb
//     },
//     "&::-webkit-scrollbar-thumb:hover": {
//       backgroundColor: "#aaaaaa", // Thumb color on hover
//     },
//     "&::-webkit-scrollbar-track": {
//       backgroundColor: "#f0f0f0", // Scrollbar track color
//     },
//   }}
// >
//   <Grid
//     container
//     sx={{
//       backgroundColor: "#F6F5F5",
//       height: "auto",
//       borderLeft: "1px solid #08082380",
//       paddingTop: "10px",
//       display: "flex",
//       alignContent: "flex-start",
//       justifyContent: "center",
//       width: "420px",
//       flexShrink: 0,
//     }}
//   >
//     <MyPortsSearchBar setPortDatas={setPortDatas} />
//     <Grid
//       container
//       spacing={4}
//       sx={{
//         paddingTop: "20px",
//         paddingLeft: "20px",
//         paddingRight: "20px",
//         display: "flex",
//         flexDirection: "column", // Items flow from top to bottom
//         flexWrap: "nowrap", // Prevent wrapping to the next row
//         justifyContent: "flex-start", // Align items to the top
//         alignContent: "center", // Center items horizontally
//         height: "50%",
//         overflowY: "auto", // Enable vertical scrolling for this section
//         overflowX: "hidden",
//         scrollbarWidth: "none",
//         "&::-webkit-scrollbar": {
//           display: "none", // Hide scrollbars in WebKit browsers
//         },
//       }}
//     >
//       {portDatas.map((investment) => {
//         // TODO set data here--------------------------------------------------------------------------------------
//         return <PersonalCard pportData={investment} />;
//       })}
//     </Grid>

//     <News
//       sx={{
//         paddingTop: "20px",
//         paddingLeft: "20px",
//         paddingRight: "20px",
//         paddingBottom: "50px",
//         display: "flex",
//         flexDirection: "column", // Items flow from top to bottom
//         flexWrap: "nowrap", // Prevent wrapping to the next row
//         justifyContent: "flex-start", // Align items to the top
//         alignContent: "center", // Center items horizontally
//         height: "100%",
//       }}
//     />
//   </Grid>
// </Box>

          );
  }
  
  function MyPortsSearchBar({setPortDatas}) {
    const [selectedSortOption, setSelectedSortOption] = React.useState("title");
    const [toggleCreateAndInvest, setToggleCreateAndInvest] = React.useState("create");
    const [direction, setDirection] = React.useState("asc");
    const [searchQuery, setSearchQuery] = React.useState("");
  
  
    React.useEffect(() => {
      const fetchData = async () => {
        
        let test;
        if(toggleCreateAndInvest === "create"){
         test = await searchPersonalPorts(searchQuery, selectedSortOption, direction, 0, SecureStorage.getItem("userId"));
        } else if (toggleCreateAndInvest === "invest"){
          test = await searchInvestPorts(searchQuery, selectedSortOption, direction, 0, SecureStorage.getItem("userId"));
        } else{
          test = await getFavoritesPorts(SecureStorage.getItem("userId"));
        }
        
        setPortDatas(test.content);
      };
      fetchData();
      const intervalId = setInterval(fetchData, 60000);

      return () => clearInterval(intervalId); 
    }, [selectedSortOption, direction, searchQuery,toggleCreateAndInvest]);
  
  const handleSortChange = (event) => {
  setSelectedSortOption(event.target.value);
  };
  const handleSearchChange = (event) => {
  setSearchQuery(event.target.value); // Update the searchQuery state
  };
  
  const  handlePortTypeToggle = () => {
  if (toggleCreateAndInvest === "create") {
    console.log("worked")
    setToggleCreateAndInvest("invest");
  }
  else if(toggleCreateAndInvest === "invest") {
    setToggleCreateAndInvest("favorite");
    
  } else{
    setToggleCreateAndInvest("create");
  }
};

  const handleDirectionToggle = () => {
    if (direction === "desc") {
      setDirection("asc");
    }
    else {
      setDirection("desc");
    }
  };
  
    return (
      <>
      
    
      <Mui.Box
        sx={{
          width: "380px",
          marginBottom: "40px",
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          // height:" 10%"
        }}
      >
        <Mui.Box
          sx={{
            width: "100%",
            display: "flex",
            displayDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Mui.Typography
            sx={{
              color: "#080823",
              fontWeight: "700",
              fontSize: "28px",
            }}
          >
            My Ports
          </Mui.Typography>
          <Mui.Box
          sx = {{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
          }}
          >
          <Mui.Select
    id="dropdown-basic"
    value={selectedSortOption}
    onChange={handleSortChange}
    sx={{
      width: "155px",
      height: "35px",
      borderRadius: "5px",
      fontSize: "20px",
      border: "0.5px solid #08082380",
      padding: "0 8px",
      ".MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    }}
  > <Mui.MenuItem value="title">Title</Mui.MenuItem>
    <Mui.MenuItem value="lastValue">Value</Mui.MenuItem>
    <Mui.MenuItem value="risk">Risk</Mui.MenuItem>
    <Mui.MenuItem value="dailyPnl">Daily Change</Mui.MenuItem>
    
  </Mui.Select>
  <Mui.IconButton
    onClick={handlePortTypeToggle}
    sx={{
      width: "35px",
      height: "35px",
      borderRadius: "5px",
      border: "0.5px solid #08082380",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "#f0f0f0",
      },
    }}
  >
    {toggleCreateAndInvest === "create" && (
      <Mui.Typography>
        C
      </Mui.Typography>
    )} {toggleCreateAndInvest === "invest" && (
      <Mui.Typography>
        I
      </Mui.Typography>
    )}
    {toggleCreateAndInvest === "favorite" && (
      <Mui.Typography>
        F
      </Mui.Typography>
    )}
  </Mui.IconButton>
  <Mui.IconButton
    onClick={handleDirectionToggle}
    sx={{
      width: "35px",
      height: "35px",
      borderRadius: "5px",
      border: "0.5px solid #08082380",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "#f0f0f0",
      },
    }}
  >
    {direction === "asc" ? (
      <ArrowUpwardIcon
        sx={{
          fontSize: "20px",
          color: "#08082380",
        }}
      />
    ) : (
      <ArrowDownwardIcon
        sx={{
          fontSize: "20px",
          color: "#08082380",
        }}
      />
    )}
  </Mui.IconButton>
  </Mui.Box>
        </Mui.Box>
        <Mui.TextField
          id="outlined-basic"
          placeholder="Search My Ports"
          value={searchQuery} 
          onChange={handleSearchChange}
          sx={{
            width: "100%", // Set the width of the Mui.TextField
            ".MuiOutlinedInput-root": {
              height: "35px", // Set the height of the input field
              borderRadius: "5px", // Rounded corners
              "& fieldset": {
                border: "0.5px solid #08082380", // Border styling
              },
            },
            ".MuiInputBase-input": {
              padding: "8px", // Adjust padding inside the input
              fontSize: "20px", // Adjust font size if necessary
              paddingLeft: "30px",
            },
          }}
        />
      </Mui.Box>
       

            </>
    );
  }

  export default MyPorts;