import React from "react"
import { Box, Typography } from "@mui/material"

interface SectorData {
  name: string
  percentage: number
}

interface SectorPercentagesProps {
  sectors: SectorData[]
}

const SectorPercentages: React.FC<SectorPercentagesProps> = ({ sectors }) => {
  return (
    <Box sx={{ width: "100%", maxWidth: 400, margin: "20px auto" }}>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold", color: "#080823" }}>
        Sector Allocation
      </Typography>
      {sectors.map((sector) => (
        <Box key={sector.name} sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
          <Typography sx={{ color: "#080823" }}>{sector.name}</Typography>
          <Typography sx={{ color: "#080823", fontWeight: "bold" }}>{sector.percentage.toFixed(2)}%</Typography>
        </Box>
      ))}
    </Box>
  )
}

export default SectorPercentages

