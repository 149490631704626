import React, { useEffect } from "react";
import SecureStorage from 'react-secure-storage';
import { useNavigate } from "react-router-dom";
import { getUserLeaderboard, getInvestmentLeaderboard, getClubLeaderboard } from "../user.js";
import {Box, Typography} from "@mui/material"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {TopBar} from "../Home/homev2.jsx";

const headerStyle = {
color: "#080823",
fontWeight: "700",
fontSize: "28px",
padding: "10px 10px 0px 10px",
}

const descStyle = {
  color: "#080823",
  fontSize: "13px",
  padding: "0px 5px 10px 12px",
  maxWidth: "650px",
  }



  function UserLeaderboard({handleProfile}) { 
    const [data, setData] = React.useState([]); 
  
    useEffect(() => { 
      const fetchData = async () => { 
        const data = await getUserLeaderboard(); 
         
        setData(data.content); 
      }; 
      fetchData(); 
    }, []); 
  
    // Helper function to find college from tags
    const getCollege = (tags) => {
      if (!tags) return "N/A";
      const collegeTag = tags.find(tag => tag.type === "college");
      return collegeTag ? collegeTag.name : "N/A";
    };
  
    return ( 
      <TableContainer component={Paper}> 
        <div style={headerStyle}>Top Individual Ports</div> 
        <div style={descStyle}>The returns for each user's port are averaged.</div> 
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table"> 
          <TableHead> 
            <TableRow> 
              <TableCell>Rank</TableCell> 
              <TableCell align="right">User</TableCell>
              <TableCell align="right">College</TableCell> 
              <TableCell align="right">Average Return&nbsp;</TableCell> 
            </TableRow> 
          </TableHead> 
          <TableBody> 
            {data.map((row, index) => ( 
              <TableRow 
                key={index+1} 
                sx={{ 
                  '&:last-child td, &:last-child th': { border: 0 }, 
                  backgroundColor: index === 0 ? '#080823' : 
                                 index === 1 ? '#080823E5' : 
                                 index === 2 ? "#080823CC" : "#F6F5F5" 
                }}
              > 
                <TableCell 
                  component="th" 
                  scope="row" 
                  sx={{ color: index < 3 ? "white" : "black" }}
                > 
                  {index+1} 
                </TableCell> 
                <TableCell 
                  align="right" 
                  onClick={() => handleProfile(row.username, row.id)} 
                  sx={{ color: index < 3 ? "white" : "black", cursor: "pointer" }}
                >
                  {row.username}
                </TableCell>
                <TableCell 
                  align="right" 
                  sx={{ color: index < 3 ? "white" : "black" }}
                >
                  {getCollege(row.tags)}
                </TableCell> 
                <TableCell 
                  align="right" 
                  sx={{ color: index < 3 ? "white" : "black" }}
                >
                  {row.avgMaxReturn.toFixed(2)}
                </TableCell> 
              </TableRow> 
            ))} 
          </TableBody> 
        </Table> 
      </TableContainer> 
    ); 
  }


function BalanceLeaderboard({handleProfile}) {
  const [data, setData] = React.useState([]);
  useEffect(() => {
      const fetchData = async () => {
          const data = await getInvestmentLeaderboard();
          
          setData(data.content);

      };
      fetchData();
  }, []);
return (
<TableContainer component={Paper}>
<div style={headerStyle}>Individual Investing</div>
<div style={descStyle}>Each user is allocated $1,500 in simulated funds and must invest in others' ports.</div>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Rank</TableCell>
            <TableCell align="right">User</TableCell>
            <TableCell align="right">Balance&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              key={index+1}
              sx={{ '&:last-child td, &:last-child th': { border: 0 },
              backgroundColor: index === 0 ? '#080823' : index === 1 ? '#080823E5' : index === 2 ? "#080823CC" : "#F6F5F5" }}
            >
              <TableCell component="th" scope="row" sx = {{ color : index < 3 ? "white" : "black"}}>
                {index+1}
              </TableCell>
              <TableCell align="right" onClick={() => handleProfile(row.username, row.id)} sx = {{ color : index < 3 ? "white" : "black", cursor: "pointer"}}>{row.username}</TableCell>
              <TableCell align="right" sx = {{ color : index < 3 ? "white" : "black"}}>
              {(isNaN(row.totalBalance) || row.totalBalance === null || row.totalBalance === undefined ? 115432 : row.totalBalance).toFixed(2)}
              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
);
}

function ClubLeaderboard() {
  const [data, setData] = React.useState([]);
  useEffect(() => {
      const fetchData = async () => {
          const data = await getClubLeaderboard();
          
          setData(data.content);

      };
      fetchData();
  }, []);
return (
<TableContainer component={Paper}>
  <div style={headerStyle}>Top Club Ports</div>
<div style={descStyle}>The returns of the top 10 performing members in each club are averaged.</div>


      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Rank</TableCell>
            <TableCell align="right">Club</TableCell>
            <TableCell align="right">Avg Return&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              key={index+1}
              sx={{ '&:last-child td, &:last-child th': { border: 0 },
              backgroundColor: index === 0 ? '#080823' : index === 1 ? '#080823E5' : index === 2 ? "#080823CC" : "#F6F5F5"
            }}
            >
              <TableCell component="th" scope="row" sx = {{ color : index < 3 ? "white" : "black"}}>
                {index+1}
              </TableCell>
              <TableCell align="right" sx = {{ color : index < 3 ? "white" : "black"}}>{row.club}</TableCell>
              <TableCell align="right" sx = {{ color : index < 3 ? "white" : "black"}}>{row.score.toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
);
}

function Leaderboard() {
  const navigate = useNavigate();
  function handleProfile(username,id) {
    // console.log(id)
    SecureStorage.setItem('u_id',username)
    console.log(SecureStorage.getItem('u_id'))
    navigate(`/profile/${username}/${id}`);
  }

  return (
    <Box>
      <TopBar/>
    <Box sx = {{padding: "20px 20px 20px 20px"}}>
      <Typography sx = {{
        color: "#080823",
        fontWeight: "700",
        fontSize: "28px",
      }}>Leaderboard</Typography>
      <Box sx = {{justifySelf: "center", justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center", padding: "20px 20px 20px 20px",gap: "80px"}}>    
      <UserLeaderboard handleProfile={handleProfile}/>
      <ClubLeaderboard/>
      <BalanceLeaderboard handleProfile={handleProfile}/>
        </Box>
      </Box>
      </Box>
  );
}

export default Leaderboard;