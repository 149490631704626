import React from "react";
import Grid from "@mui/material/Grid2";
import portsImage from "../New_Landing_Page/ports_logo.png";
import {SignInButton, SignUpButton} from "../New_Landing_Page/HeaderButtons";
import {Card} from "../Cards/cardv2";
import * as Mui from "@mui/material";
import { searchPublicPorts } from "../user.js";
import { useNavigate } from "react-router-dom";


function BrowseGuestPage() {
  return (
    <>
    <Grid container spacing={3} sx={{ backgroundColor: "#080823", padding: "10px", alignItems: "center" }}>
          <Grid item md={6} xs={4}>
            <img
              src={portsImage}
              alt="Ports Logo"
              style={{ width: "60px", }}
            />
          </Grid>
          <Grid
            container
            spacing={3}
            size="grow"
            sx={{ alignItems: "center", justifyContent: "right" }}
          >
            <Grid item md={6} xs={4}>
              <SignUpButton />
            </Grid>
            <Grid item md={6} xs={4}>
              <SignInButton />
            </Grid>
          </Grid>
        </Grid>
        <DemoMarket/>
        </>
  );
}

function DemoMarket() {
    const navigate = useNavigate();
    const [portDatas, setPortDatas] = React.useState([]);
    
    React.useEffect(() => {
        const fetchData = async () =>{
            const ports = await searchPublicPorts("", "title", "asc", 0, null);
            
            setPortDatas(ports.content);    
        }
        fetchData();
        },[]);

    const setPortData = () => {
        navigate("/register");
    }

  
    return (
      <Grid
      container
      sx={{
        position: "relative",
        backgroundColor: "#F6F5F5",
        padding: "10px 20px 10px 20px",
        flexGrow: 1,
        display: "flex",
        alignContent: "flex-start",
        justifyContent: "center",
        // Align rows to the top
      }}
    >
      <Grid
        container
        spacing={8}
        sx={{
          paddingTop: "20px",
          display: "grid", // Switch to CSS grid for precise row alignment
          gridTemplateColumns: "repeat(auto-fit, 221px)", // Auto-adjust column count
          justifyContent: "center",
          width: "100%",
          height: "100%", // Fixed height for the grid container
          overflowY: "auto", // Enable vertical scrolling
          overflowX: "hidden", // Prevent horizontal scrolling
          scrollbarWidth: "none", // For Firefox
          alignItems: "start",
          justifyContent: "center",
      "&::-webkit-scrollbar": { // For Chrome, Safari, Edge
        display: "none",
      },
        }}
      >
        {portDatas.map((portData) => {
          return (
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center", // Center items within each cell
                alignItems: "center",
              }}
            >
              <Card portData={portData} setIsClicked={setPortData} />
            </Grid>
          );
        })}
        <Mui.Box sx = {{
          height: "500px",
        }}>

        </Mui.Box>
      </Grid>
    </Grid>
    );
  }

export default BrowseGuestPage;